.footer {
  background-color: var(--footer-background-color);
  padding: var(--footer-padding-top) var(--footer-padding-right)
    var(--footer-padding-bottom) var(--footer-padding-left);
  text-align: var(--footer-align);
  color: var(--footer-text-color);

  * {
    color: inherit;
  }
}
